
import React, { useEffect, useRef, useState } from "react";
import InnerHeader from "./InnerHeader";
import "../assets/style/BlogDetails.css";
import {
  FaUserCircle,
  FaSortDown,
  FaTimes,
  FaUser,
  FaShareAlt,
} from "react-icons/fa";
import Modal from "react-modal";
import { FaGoogle } from "react-icons/fa";
import { auth, provider } from "./google";
import { signInWithPopup, signOut } from "firebase/auth";
import { useLocation, useParams } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { Helmet } from "react-helmet-async";
import { BlogDetailApi } from "./Api";
import moment from "moment/moment";
import ClipLoader from "react-spinners/ClipLoader";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const BlogDetail = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhotoURL, setUserPhotoURL] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  let { id } = useParams();
  const [blog, setBlog] = useState();

  const closeModal = () => setModalIsOpen(false);

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const username = user.displayName;
      const photoURL = user.photoURL;

      setUserEmail(email);
      setUserName(username || "");
      localStorage.setItem("email", email);
      localStorage.setItem("username", username || "");
      localStorage.setItem("photoURL", photoURL || "");

      closeModal();
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };



  const metaTags = {
    title: blog?.title,
    description: blog?.description,
    image: `${REACT_APP_BASE_URL}/${blog?.detailphoto}`,
    keywords: blog?.keywords || "default, keywords",
    url: window.location.href,
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedUsername = localStorage.getItem('username');
    const storedPhotoURL = localStorage.getItem('photoURL');

    setUserEmail(storedEmail);
    setUserName(storedUsername);
    setUserPhotoURL(storedPhotoURL || "");

    const BlogDetailFetch = async () => {
      try {
        setIsLoading(true);
        const response = await BlogDetailApi(id);
        setBlog(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    BlogDetailFetch();
  }, []);


  return (
    <>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
        <meta property="og:image" content={metaTags.image} />
        <meta property="og:url" content={metaTags.url} />
        <meta property="og:site_name" content="neosao.com" />
        <meta name="keywords" content={metaTags.keywords} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTags.title} />
        <meta name="twitter:description" content={metaTags.description} />
        <meta name="twitter:image" content={metaTags.image} />

        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="330" />
        <meta name="description" content={metaTags.description} />
      </Helmet>

      <InnerHeader />

      {
        isLoading ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
          width: '100%'   
        }}>
          <ClipLoader color="#1D407F" size={50} />
        </div> : blog && (
          <div className="blog-details">
            <main>
              <section className="section1">
                <div className="blog-details-sectin-1-title">
                  <h2>{blog?.title}</h2>
                </div>
              </section>

              <section className="section2">
                <div className="section-img">
                  <img src={`${REACT_APP_BASE_URL}/${blog?.detailphoto}`} alt={blog?.title} className="sec-img" />
                </div>
              </section>

              <section className="section3">
                <div className="blog-details-section-3-share">
                  <div className="blog-details-section-3-share-post">

                    <div className="top-title">
                      <div className="author-img">
                        <p className="article-author mb-0">
                          <FaUser className="mr-2"
                            style={{
                              fontSize: "40px",
                              border: "1px solid grey",
                              padding: "5px",
                              borderRadius: "50px"
                            }}
                          />
                          {blog?.author_name ?? "Neosao Services"}
                        </p>
                        <p className="mb-0">{blog?.created_at !== "" ? moment(blog?.created_at).format("ddd, MMM DD, YYYY") : ""}</p>
                      </div>

                      <div className="blog-details-section-3-share-post-title">
                        <h3>Share Post</h3>
                        <div className="blog-details-section-3-share-post-icons">
                          <div className="social-icons">
                            <FacebookShareButton url={window.location.href} quote={blog?.title}>
                              <FacebookIcon className="icon" size={32} round />
                            </FacebookShareButton>
                            <LinkedinShareButton url={window.location.href}>
                              <LinkedinIcon className="icon" size={32} round />
                            </LinkedinShareButton>
                            <TwitterShareButton url={window.location.href} title={blog?.title}>
                              <TwitterIcon className="icon" size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton url={window.location.href} title={blog?.title}>
                              <WhatsappIcon className="icon" size={32} round />
                            </WhatsappShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blog-line"></div>
                    <div className="blog-details-section-3-list">
                      <p
                        dangerouslySetInnerHTML={{ __html: blog?.description }}
                      >
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        )
      }


      {/* {
        modalIsOpen && (
          <div className="modal-overlay">
            <div className="box-modal-google">
              <FaTimes className="close-icon-google" onClick={closeModal} />
              <div className="google">
                <h2 className="mt-5">Please login to write Comment</h2>
                <button className="google-signup-btn mt-5" onClick={handleSignIn}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="l"><g id="google"><g id="google-vector" fill-rule="evenodd" clip-rule="evenodd"><path id="Shape" fill="#4285F4" d="M20.64 12.205q-.002-.957-.164-1.84H12v3.48h4.844a4.14 4.14 0 0 1-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615"></path><path id="Shape_2" fill="#34A853" d="M12 21c2.43 0 4.468-.806 5.957-2.18L15.05 16.56c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H3.958v2.332A9 9 0 0 0 12.001 21"></path><path id="Shape_3" fill="#FBBC05" d="M6.964 13.712a5.4 5.4 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71V7.96H3.957A9 9 0 0 0 3 12.002c0 1.452.348 2.827.957 4.042z"></path><path id="Shape_4" fill="#EA4335" d="M12 6.58c1.322 0 2.508.455 3.441 1.346l2.582-2.58C16.463 3.892 14.427 3 12 3a9 9 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71"></path></g></g></svg>
                  Sign Up with Google
                </button>
              </div>
            </div>
          </div>
        )
      } */}
    </>
  );
};

export default BlogDetail;
